import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { NewsList } from '@mangoart/gatsby-ui/components/ezagrar/NewsList';
import DefaultLayout from '../../layout/DefaultLayout';

const AktionsmodellePage = ({ data, location }) => {
  const { pageData, siteData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      {news && news.edges && <NewsList data={news} />}
    </DefaultLayout>
  );
};

export default AktionsmodellePage;

export const AktionsmodellePageQuery = graphql`
  query AktionsPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageAktionsmodelle {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
    news: allCockpitNews(
      filter: { sites: { in: "lamborghini-traktoren.at" }, category: { eq: "Aktionsmodell" }, published: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          category
          teaser
          slug
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          file {
            id
            publicURL
          }
          images {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
